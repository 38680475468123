$(document).ready(function(){

	$(window).resize(function() {
		var width = $(window).width();

		if (width < 500) {
			$("body").addClass("responsive-lvl3");
			$("body").removeClass("responsive-lvl1");
			$("body").removeClass("responsive-lvl2");
		} else if (width < 700) {
			$("body").addClass("responsive-lvl2");
			$("body").removeClass("responsive-lvl1");
			$("body").removeClass("responsive-lvl3");
		} else if (width < 955) {
			$("body").addClass("responsive-lvl1");
			$("body").removeClass("responsive-lvl2");
			$("body").removeClass("responsive-lvl3");
		} else {
			$("body").removeClass("responsive-lvl1");
			$("body").removeClass("responsive-lvl2");
			$("body").removeClass("responsive-lvl3");
		}

	});

	$(window).trigger("resize");
});
